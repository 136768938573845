<script setup>
	import { storeToRefs } from 'pinia';
	import { useWindowStore } from '@/stores/window';
	const { innerWidth } = storeToRefs(useWindowStore());
	const props = defineProps({
		page: {
			type: Object,
			required: true,
		},
	});

	const { page } = toRefs(props);
	const { href } = useRoute();

	const shortName = (name) => {
		const length = innerWidth.value > 370 ? 25 : 15;
		if (name && name.length > length) {
			return name.substring(0, length) + '...';
		}
		return name;
	};
	
    const breadcrumbs = computed(() => {
		    const hasBreadcrumbs = page.value?.breadcrumbs?.itemListElement?.length > 0;
		    if (!hasBreadcrumbs) {return false};

        return page.value.breadcrumbs.itemListElement.map((item, index) => {
    				if (index === 0) {return false};
						if (item.item === '/iframe') {return false};
    				return {
		    			name: shortName(item.name),
			    		url: item.item,
				    };
        }).filter(Boolean);
	});
</script>
<template>
	<div class="breadcrumbs-wrapper" v-if="breadcrumbs && breadcrumbs.length">
		<div class="container">
			<ul class="breadcrumbs fs-12" :class="breadcrumbs.length >= 2 ? 'mobile-chop' : null">
				<li v-for="(item, index) in breadcrumbs">
					<MessLink v-if="item.name && item.url && item.url != href" :href="item.url" class="base">
						<span v-html="item.name" tag="span" />
					</MessLink>
					<span v-else class="active">
						<span v-html="item.name" tag="span" />
					</span>
					<span class="pipe" v-if="index < breadcrumbs.length - 1">></span>
				</li>
			</ul>
		</div>
	</div>
</template>

<style lang="scss">
	.breadcrumbs-wrapper {
		position: absolute;
		top: var(--header-height);
		left: 50%;
		transform: translateX(-50%);

		background: var(--gray-100);
		padding: 1.5rem 0;
		width: 100%;
		z-index: 1;

		.container {
			.breadcrumbs {
				display: flex;
				align-items: center;
				padding: 0;
				margin: 0;
				list-style: none;
				gap: 1rem;

				li {
					color: #000;

					font-size: 10px;
					font-style: normal;
					font-weight: 800;
					line-height: 123.1%; /* 12.31px */
					letter-spacing: 1.15px;
					text-transform: uppercase;

					* {
						color: #000;
						font-size: 10px;
						font-style: normal;
						font-weight: 800;
						line-height: 123.1%; /* 12.31px */
						letter-spacing: 1.15px;
						text-transform: uppercase;

						transition: 250ms ease-in-out;

						&.mess-link {
							border-bottom: 1px solid;
							&:hover {
								color: var(--gray-600);
							}
						}
					}

					.pipe {
						margin-left: 1rem;
						border-bottom: 0;
						&:hover {
							color: var(--black);
						}
					}
				}
			}
		}

		@media (max-width: $mobile) {
			.container {
				.breadcrumbs {
					&.mobile-chop {
						li:nth-of-type(n + 2) {
							display: none;
						}
						li:nth-of-type(1),
						li:nth-last-of-type(1) {
							display: block;
						}
					}
				}
			}
		}
	}
</style>
